import DocsLayout from "components/docs/layout/DocsLayout";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { useIsMobile } from "hooks/useIsMobile";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import styled, { css } from "styled-components";
import { palette } from "styles/theme";
import type { RouterLocation } from "types/types";
import DeprecatedH1 from "components/deprecated/DeprecatedH1";
import DeprecatedH2 from "components/deprecated/DeprecatedH2";

interface Props {
  location: RouterLocation;
}

const ContentContainer = styled(Container)`
  padding-top: 100px;
  padding-bottom: 100px;
`;

const CenteredCol = styled(Col).attrs({ className: "d-flex align-items-center" })``;

// Gradient blue background with the magic of fill color
const Subtitle = styled(DeprecatedH2)<{ $isMobile: boolean }>`
  && {
    text-transform: uppercase;
    width: fit-content;
    color: transparent;
    background-image: linear-gradient(
      91.61deg,
      #00288d 0%,
      #1782ff 36.24%,
      #0f52ff 68.58%,
      #00288d 100%,
      #00288d 100%
    );
    -webkit-background-clip: text;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
    ${({ $isMobile }) =>
      $isMobile
        ? css`
            font-size: 28px;
            line-height: 36px;
          `
        : css`
            font-size: 40px;
            line-height: 48px;
          `}
  }
`;

const Title = styled(DeprecatedH1)<{ $isMobile: boolean }>`
  && {
    ${({ $isMobile }) =>
      $isMobile
        ? css`
            font-size: 56px;
            line-height: 64px;
          `
        : css`
            font-size: 72px;
            line-height: 80px;
          `}
    margin-bottom: 32px;
  }
`;

const Description = styled.p`
  && {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 32px;
  }
`;

const Button = styled(Link).attrs({ className: "weight-semibold" })`
  && {
    font-size: 16px;
    line-height: 24px;
    border-radius: 24px;
    padding: 12px 20px;
    transition: background-color 0.2s ease-in-out;
    color: ${palette.white};
    background: ${palette.black};
    &:hover {
      text-decoration: none;
      color: ${palette.white};
      background: ${palette.blue};
    }
  }
`;

const meta = (
  <Helmet>
    <title>Page Not Found - Merge</title>
    <meta name="title" content="Page Not Found - Merge" />
    <meta name="description" content="No page found at that url" />
  </Helmet>
);

/**
 * Creates a custom 404 page with nice image and big design. Uses client
 * side rendering ONLY to handle layout related changes, as this component
 * is SSR generically once, and then used in different contexts client side.
 * There's no logic on the server to know if this page is rendered within
 * docs or not.
 */
const NotFoundPage = ({ location }: Props) => {
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!("window" in global)) {
      return;
    }
  }, [location.pathname]);

  const notFoundContent = (
    <div>
      <Subtitle $isMobile={isMobile}>404 Error</Subtitle>
      <Title $isMobile={isMobile}>Page Not Found</Title>
      <Description>Oops! We can&apos;t find the page you&apos;re looking for.</Description>
      <Button to={"/"}>Back to Docs Home</Button>
    </div>
  );

  const content = (
    <>
      {meta}
      <ContentContainer>
        <Row>
          <CenteredCol xs={12} md={6}>
            {notFoundContent}
          </CenteredCol>
          {!isMobile && (
            <CenteredCol xs={12} md={6}>
              <StaticImage
                src="../assets/images/background/mark-perspective-slight-right-1.png"
                alt=""
                quality={100}
                placeholder="none"
                layout="constrained"
                loading="eager"
              />
            </CenteredCol>
          )}
        </Row>
      </ContentContainer>
    </>
  );

  return <DocsLayout location={{ ...location, key: "404" }}>{content}</DocsLayout>;
};

export default NotFoundPage;
