import { useEffect } from "react";

/**
 * Allows for doing something on a resize event - in a nice debounced fashion
 * using `requestAnimationFrame`. IMPORTANT: Make sure your handler is surrounded
 * by a `useCallback` whereever you're calling this hook from, otherwise this
 * `useEffect` runs on EVERY resize and that defeats the whole purpose of this.
 */
const useResizeHandler = (handler: () => void) => {
  useEffect(() => {
    let timeout: number | null = null;

    // Uses requestAnimationFrame + a timeout variable to run smoothly
    const handleResize = () => {
      if (timeout) {
        window.cancelAnimationFrame(timeout);
      }
      timeout = window.requestAnimationFrame(handler);
    };

    // Event listeners around the resize for window
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handler]);
};

export default useResizeHandler;
