import { useCallback, useState } from "react";
import useResizeHandler from "./useResizeHandler";

interface Size {
  width: number | null;
  height: number | null;
}

/**
 * Allows for querying for current window size with a hook. Compatible with client & server renders.
 * Uses the first element child of the document, which will be the html node, to calculate size instead
 * of window so we can exclude the scroll bars from the window size.
 */
export const useWindowSize = () => {
  const [size, setSize] = useState<Size>({
    width: null,
    height: null,
  });

  const onResize = useCallback(() => {
    const htmlNode = document.firstElementChild;
    setSize({
      width: htmlNode?.clientWidth ?? null,
      height: htmlNode?.clientHeight ?? null,
    });
  }, []);

  useResizeHandler(onResize);

  return size;
};
