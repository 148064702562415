import { useMemo } from "react";
import { useWindowSize } from "./useWindowSize";

// Most of the site switches to a more responsive design at this size
const MOBILE_TRIGGER_VALUE = 768;

/**
 * Allows for querying if we're on "mobile" (under 768 pixels) or not - generally
 * most of the site has breakpoints in CSS around 768. and this allows for doing the
 * same from JS too.
 */
export const useIsMobile = (): boolean => {
  const { width } = useWindowSize();
  const isMobileWindowWidth = useMemo(() => (width ?? Infinity) < MOBILE_TRIGGER_VALUE, [width]);
  return isMobileWindowWidth;
};
